import "./definitions"

const reachLog = (...args: string[]) => console.log.apply(console, ["REACH", ...args])
const reachError = (...args: string[]) => console.error.apply(console, ["REACH", ...args])

function injectReachToolsPanel(...params: string[]) {
  const { id } = tools.stringParamsIntoObject(...params)

  const reachToolsPanel = document.querySelector<HTMLElement>(`#${id}`)

  if (!reachToolsPanel) {
    return
  }

  reachToolsPanel.classList.add("reach--tools-panel")
  reachToolsPanel.style.visibility = "hidden"

  function getSearchText() {
    return window.Reach?.getTranslatedText(window.Reach?.translations.navigation.search) ?? ""
  }

  window.addEventListener("languagechange", () => {
    setTimeout(() => {
      const element = document.querySelector("#reach-search-button")
      if (element) {
        element.innerHTML = getSearchText()
      }
    }, 125)
  })

  reachToolsPanel.innerHTML = `
  <div id="reach--categories"></div>
  <div class="reach--search-and-cart" style="display: none">
    <div class="horizontal-menu-container horizontal-desktop">
      <nav class="horizontal-menu horizontal-menu--desktop">
        <div class="horizontal-menu-item">
          <a id="reach-search-button" href="#!/~/search" style="display: none">${getSearchText()}</a>
        </div>
      </nav>
    </div>
    <div class="ec-cart-widget" style="display: none">
    </div>
  </div>
`
  window.xCategoriesV2("id=reach--categories")
  initCategoriesModifications()
  initSearchLinkModifications()
  document.querySelector(".reach--search-and-cart")?.setAttribute("style", "")

  window.Reach!.onLoad = {
    fn: () => {
      initMinicartModifications()
    },
    options: {
      skipDynamicInit: true,
    },
  }
}

function initCategoriesModifications() {
  const reachCategories = document.querySelector("#reach--categories")
  if (!(reachCategories instanceof HTMLElement)) {
    return
  }

  const handleOpenMobileMenu = (e: Event) => {
    if (
      !(
        (e.target instanceof HTMLElement || e.target instanceof SVGElement) &&
        e.target.closest(".horizontal-menu-button")
      )
    ) {
      return
    }

    const mobileMenu = document.body.querySelector(".horizontal-menu.horizontal-menu--mobile")
    const isGlobal = mobileMenu?.parentElement === document.body

    if (!(mobileMenu instanceof HTMLElement)) {
      return
    }

    if (!isGlobal) {
      reachCategories
        .querySelector(".horizontal-menu.horizontal-menu--mobile")
        ?.classList.toggle("horizontal-menu--mobile--mobileShow")
    } else {
      window.Ecwid?.OnPageSwitch.add(() => {
        mobileMenu.classList.remove("horizontal-menu--mobile--mobileShow")
      })
      moveElementFromOnePlaceToAnother(mobileMenu, reachCategories)
      mobileMenu.classList.toggle("horizontal-menu--mobile--mobileShow")
      document.querySelector("body")?.addEventListener(
        "click",
        (e) => {
          if (e.target instanceof HTMLElement && e.target.closest("#reach--categories")) {
            return
          }
          mobileMenu.classList.remove("horizontal-menu--mobile--mobileShow")
        },
        { capture: true },
      )
    }

    e.preventDefault()
    e.stopImmediatePropagation()
  }

  reachCategories.addEventListener("touchend", handleOpenMobileMenu, { capture: true })
  reachCategories.addEventListener("click", handleOpenMobileMenu, { capture: true })

  function moveElementFromOnePlaceToAnother(element: HTMLElement, destination: HTMLElement) {
    const parent = element.parentElement
    if (!parent) {
      throw new Error("Element doesn't have a parent")
    }
    parent.removeChild(element)
    destination.appendChild(element)
  }
}

function initMinicartModifications() {
  const ecCartWidget = document.querySelector(".ec-cart-widget")
  if (!ecCartWidget) {
    return
  }

  ecCartWidget.innerHTML = `
<div
  class="ec-storefront-v2 ec-minicart ec-minicart--m ec-minicart--inline ec-minicart--responsive ec-minicart--no-shape ec-minicart--animation-default"
  tabindex="0" role="button" aria-label="Varukorg">
  <div class="ec-minicart__body">
    <div class="ec-minicart__icon">
      <svg class="icon-default" width="36" height="30" viewBox="0 0 36 30" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <path d="M7 7h22v18a4 4 0 0 1-4 4H11a4 4 0 0 1-4-4V7z" stroke="currentColor"
                stroke-width="2"></path>
          <path d="M13 10V6c0-2.993 2.009-5 5-5s5 2.026 5 5v4" stroke="currentColor" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"></path>
          <circle class="cart-not-empty" cx="18" cy="18" r="4" fill="currentColor"></circle>
        </g>
      </svg>
    </div>
    <div class="ec-minicart__wrap">
      <div class="ec-minicart__counter" style="display: none"></div>
    </div>
  </div>
</div>         
`

  ecCartWidget.setAttribute("style", "") // show the mini cart

  ecCartWidget.addEventListener("click", () => {
    window.Ecwid?.openPage("cart")
  })

  function setMiniCartWidgetState(quantity: number) {
    const counter = ecCartWidget?.querySelector(".ec-minicart__counter")
    if (!counter) {
      return
    }
    if (quantity === 0) {
      counter.setAttribute("style", "display:none")
    } else {
      counter.setAttribute("style", "")
      counter.textContent = quantity.toString()
    }
  }

  window.Ecwid?.OnCartChanged.add((cart) => {
    setMiniCartWidgetState(cart.productsQuantity)
  })

  window.Ecwid?.Cart.get((cart) => setMiniCartWidgetState(cart.productsQuantity))
}

function initSearchLinkModifications() {
  const reachSearchButton = document.querySelector("#reach-search-button")
  reachSearchButton?.setAttribute("style", "")

  if (!reachSearchButton) {
    return
  }

  function hasHashSearch() {
    return window.location.hash.startsWith("#!/~/search")
  }

  function controlActiveClassOfSearchNavItem() {
    const navigationItem = reachSearchButton?.closest(".horizontal-menu-item")
    const activeClassName = "horizontal-menu-item--active-brute"
    if (hasHashSearch()) {
      navigationItem?.classList.add(activeClassName)
    } else {
      navigationItem?.classList.remove(activeClassName)
    }
  }

  window.addEventListener("hashchange", controlActiveClassOfSearchNavItem)
  controlActiveClassOfSearchNavItem()
}

function initHardcodedEcwidConfig() {
  window.ec = window.ec ?? Object()
  window.ec.storefront = window.ec?.storefront || Object()
  window.ec.storefront.show_root_categories = true // hides the sign-in link
  window.ec.storefront.show_signin_link = false // hides the sign-in link
  window.ec.storefront.show_footer_menu = false
  window.ec.storefront.product_filters_opened_by_default_on_category_page = false
  window.ec.storefront.product_list_subtitles_behavior = true
  window.ec.storefront.product_filters_position_category_page = "LEFT"
  window.ec.storefront.product_filters_position_category_page = "LEFT"
  window.ec.storefront.product_list_buybutton_behavior = "SHOW"
  window.ec.storefront.product_list_show_additional_image_on_hover = false
  window.ec.storefront.product_details_show_save_for_later = false
  window.ec.storefront.product_details_show_share_buttons = false
  window.ec.storefront.product_details_show_vk_share_button = false
  window.ec.storefront.product_details_show_twitter_share_button = false
  window.ec.storefront.product_details_show_pinterest_share_button = false
  window.ec.storefront.product_details_show_facebook_share_button = false

  window.ec.config = window.ec.config || Object()
  window.ec.config.chameleon = window.ec.config.chameleon || Object()
  window.ec.config.chameleon.font = "auto"
  window.ec.config.chameleon.colors = {
    "color-link": "#0062af",
  }
}

function initDynamicEcwidConfig(element: HTMLElement = document.body) {
  const detectedFontFamily = window.getComputedStyle(element).fontFamily
  if (detectedFontFamily) {
    window.ec.config.chameleon.font = detectedFontFamily
  }
}

const tools = {
  stringParamsIntoObject: (...params: string[]) =>
    Object.fromEntries(params.map((param) => param.split("="))),
}

window.xReachStore = async (...params: string[]) => {
  const { id } = tools.stringParamsIntoObject(...params)

  const elementToInjectStore = document.querySelector(`#${id}`)

  if (!(elementToInjectStore instanceof HTMLElement)) {
    reachError(`Element with id=${id} couldn't be found`)
    return
  }

  initHardcodedEcwidConfig()
  initDynamicEcwidConfig(elementToInjectStore)
  reachLog("initializing")
  reachLog("loaded")

  elementToInjectStore.innerHTML = `
<div id="reach--tools-panel"></div>
<div class="reach--store">
  <div id="reach--store"></div>
</div>
`

  window.xProductBrowser("id=reach--store", "views=grid(10,4)")
  injectReachToolsPanel("id=reach--tools-panel")
}

window.xReachStore("id=store")
