interface Window {
  xProduct?: () => void

  Ecwid?: {
    OnAPILoaded: {
      add: (arg: Function) => void
    }
    OnPageLoaded: {
      add: (arg: (page: { type: string }) => void) => void
    }
    OnPageSwitch: {
      add: (arg: (page: { type: string }) => void) => void
    }
    OnCartChanged: {
      add: (arg: (cart: { productsQuantity: number }) => void) => void
    }
    Cart: {
      get: (arg: (cart: { productsQuantity: number }) => void) => void
    }
    getInitializedWidgets: () => string[]

    openPage: (type: string, options?: { id: string }) => void
    acceptLanguage: string[]
    destroy: () => void
    resizeProductBrowser: () => void
    xCategoriesV2: (param: string) => void
  }

  ecwid_script_defer?: boolean
  ecwid_dynamic_widgets?: boolean

  ec: {
    config: {
      disable_all_cookies: boolean
      chameleon: { colors: Record<string, any>; font: string }
    }
    storefront: Record<string, any>
  }

  _xnext_initialization_scripts?: any[]

  ecwid_onBodyDone?: () => void

  xReachStore?: (params: string) => void
  xReachPanel: (params: string) => void
  xCategoriesV2: (params: string) => void
  xProductBrowser: (...params: string[]) => void
}
