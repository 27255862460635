import { hardcodedProductionStoreId } from "./hardcoded-production-store-id"

declare global {
  interface Window {
    Reach?: ReachPublicInterfaceObject
    xProduct?: () => void

    ecwid_script_defer?: boolean
    ecwid_dynamic_widgets?: boolean

    _xnext_initialization_scripts?: any[]

    ecwid_onBodyDone?: () => void
  }
}

class ReachPublicInterfaceObject {
  private onLoadFunctions: Function[] = []
  private isLoaded = false

  private scriptInjector: OriginalScriptInjector

  private readonly defaultOnLoadOptions = { skipDynamicInit: false }

  public getTranslatedText = getTranslatedText

  public get translations() {
    return translations
  }

  constructor({ scriptInjector }: { scriptInjector: OriginalScriptInjector }) {
    this.scriptInjector = scriptInjector

    this.scriptInjector.addOnLoad(() => {
      window.Ecwid?.OnAPILoaded.add(() => {
        this.setToLoaded()
      })
    })
  }

  private parseOnLoadArgs(
    arg: Function | { fn: Function; options?: { skipDynamicInit: boolean } } | unknown,
  ) {
    if (typeof arg === "function") {
      return [arg, this.defaultOnLoadOptions] as const
    }
    if (typeof arg === "object" && arg != null) {
      const fn = "fn" in arg && typeof arg.fn === "function" ? arg.fn : undefined
      const options =
        "options" in arg && typeof arg.options === "object"
          ? { ...this.defaultOnLoadOptions, ...arg.options }
          : this.defaultOnLoadOptions
      if (fn != null && options != null) {
        return [fn, options] as const
      }
    }

    return undefined
  }

  set onLoad(arg: Function | { fn: Function; options?: { skipDynamicInit: boolean } } | unknown) {
    const result = this.parseOnLoadArgs(arg)
    if (result == null) {
      return
    }

    const [fn, options] = result

    if (this.isLoaded === true) {
      fn()
    } else {
      if (!options.skipDynamicInit) {
        this.scriptInjector.inject({ isDynamic: true })
      }
      this.onLoadFunctions.push(fn)
    }
  }

  setToLoaded() {
    if (this.isLoaded === true) {
      return
    }
    this.isLoaded = true
    setTimeout(() => {
      this.onLoadFunctions.forEach((fn) => fn())
    }, 0)
  }
}

if (window.Reach == null) {
  safelyInitCookieBlockerWithFallback()

  const originalScriptInjector = createOriginalScriptInjector()

  initXWidgetCallsForwarder(originalScriptInjector)

  window.Reach = new ReachPublicInterfaceObject({ scriptInjector: originalScriptInjector })

  originalScriptInjector.addOnLoad(() => {
    initAttemptsToCallReachSetLoaded()
  })

  initializeCheckoutFullNameValidator()
  initializeCountryAddressModificator()
  initializeProductModalOpening()
}

type OriginalScriptInjector = ReturnType<typeof createOriginalScriptInjector>

function createOriginalScriptInjector() {
  function getQueryParametersForEcwidScriptUrl() {
    // const scriptTag = document.querySelector("#reach-init-script")
    // const url = scriptTag?.getAttribute("src")
    // return url?.split("?")[1] ?? ""
    return `${hardcodedProductionStoreId}&&lang=sv`
  }

  function createEcwidScriptTag(queryStringWithParameters: string) {
    const ecwidScriptUrl = "https://app.ecwid.com/script.js"
    const node = document.createElement("script")
    node.setAttribute("src", `${ecwidScriptUrl}?${queryStringWithParameters}`)
    return node
  }

  let onBeforeInjectHandlers: Function[] = []

  function addOnBeforeInject(fn: Function) {
    onBeforeInjectHandlers.push(fn)
  }

  let onLoadHandlers: Function[] = []

  function addOnLoad(fn: Function) {
    onLoadHandlers.push(fn)
  }

  let injectionInitiated = false

  function localOnLoad() {
    onLoadHandlers.forEach((fn) => fn())
  }

  function inject({ isDynamic = false }: { isDynamic?: boolean } = {}) {
    if (injectionInitiated) {
      return
    }

    if (isDynamic) {
      window.ecwid_script_defer = true
      window.ecwid_dynamic_widgets = true
    }

    injectionInitiated = true
    onBeforeInjectHandlers.forEach((fn) => fn())
    document.body.append(node)
  }

  const queryStringParams = getQueryParametersForEcwidScriptUrl()
  const node = createEcwidScriptTag(queryStringParams)
  node.onload = localOnLoad

  return {
    inject,
    addOnBeforeInject,
    addOnLoad,
  }
}

function initXWidgetCallsForwarder(
  originalScriptInjector: ReturnType<typeof createOriginalScriptInjector>,
) {
  registerXInitCallsWrappers()

  originalScriptInjector.addOnLoad(() => {
    retryAllRegisteredXProductCalls()
  })

  originalScriptInjector.addOnBeforeInject(() => {
    removeXInitCallsWrappers()
  })

  const xProductCalls: unknown[][] = []
  const xProductBrowserCalls: unknown[][] = []
  const xCategoriesV2Calls: unknown[][] = []

  const timeoutToWaitForMoreXCalls = 125
  let injectionScriptTimeoutHandler: number | undefined = undefined
  const triggerScriptInjection = () => {
    injectionScriptTimeoutHandler = setTimeout(
      originalScriptInjector.inject,
      timeoutToWaitForMoreXCalls,
    )
  }

  // retry xProduct calls once the initial script is loaded
  function retryAllRegisteredXProductCalls() {
    // @ts-ignore
    xProductCalls.forEach((args) => {
      // @ts-ignore
      window.xProduct(...args)
    })

    xProductBrowserCalls.forEach((args) => {
      // @ts-ignore
      window.xProductBrowser(...args)
    })

    xCategoriesV2Calls.forEach((args) => {
      // @ts-ignore
      window.xCategoriesV2(...args)
    })
  }

  function registerXInitCallsWrappers() {
    //@ts-ignore
    window.xProduct = function (...args) {
      xProductCalls.push(args)
      triggerScriptInjection()
    }
    // @ts-ignore
    window.xProductBrowser = function (...args) {
      xProductBrowserCalls.push(args)
      triggerScriptInjection()
    }
    // @ts-ignore
    window.xCategoriesV2 = function (...args) {
      xCategoriesV2Calls.push(args)
      triggerScriptInjection()
    }
  }

  function removeXInitCallsWrappers() {
    // @ts-ignore
    window.xProduct = undefined
    // @ts-ignore
    window.xProductBrowser = undefined
    // @ts-ignore
    window.xCategoriesV2 = undefined
  }
}

function safelyInitCookieBlockerWithFallback() {
  try {
    initCookieBlocker()
  } catch (e) {
    console.error(e)
    console.error("Reach - selective cookie blocker is not enabled")
    console.log("Fallbacks to turning off all cookies")

    window.ec = window.ec || Object()
    window.ec!.config = window.ec!.config || Object()
    window.ec!.config!.disable_all_cookies = true
  }
}

function initCookieBlocker() {
  const allowListRegexps = [/^PSecwid__(\d+)PScart$/]
  // generated with tools/ecwid-cookies
  const blockListPrefixes = [
    "PSecwid__",
    "PSecwid__document__referrerPSreferrer",
    "PSecwid__document__referrerPSreferrer_expire",
    "PSorder__result__",
    "PSecwid__shopping__cart__controller__storagePSlastPlace",
    "PSecwid__shopping__cart__controller__storagePSlastPlace_expire",
    "ec-user-response",
    "ec-user-response-issued-at",
    "PSecwid__utm__storagePSutm__data",
    "PSecwid__utm__storagePSutm__data_expire",
    "__eca_s_id_",
    "__eca_v_id_",
    "PSdispatcher__content__idsPS",
    "PSdispatcher__placed__ordersPS",
    "PSec__custom__code__idPS",
    "PSreloadTriggerPSreloadTrigger",
    "PSreloadTriggerPSreloadTrigger_expire",
    "PSecwid__agree__personal__data_usage__",
    "PSecwid__facebookPSlastUserId",
    "PSecwid__facebookPSlastUserId_expire",
    "PSlast__historyPSts",
    "PSlast__historyPSts_expire",
    "PSlast__historyPStoken",
    "PSlast__historyPStoken_expire",
    "__paypal_storage__",
    "ts_c",
    "akavpau_ppsd",
    "Google Tag Manager",
    "_ga",
    "_gid",
    "_fbp",
    "_swa_u",
  ]

  function isEcwidBlockListedCookie(key: string) {
    const isBlockListed = blockListPrefixes.some((prefix) => key.indexOf(prefix) === 0)
    return isBlockListed
  }

  function isEcwidAllowListedCookie(key: string) {
    const isOnAllowList = allowListRegexps.some((x) => key.match(x))
    return isOnAllowList
  }

  function shouldCookieBeBlocked(cookieName: string) {
    return !isEcwidAllowListedCookie(cookieName) && isEcwidBlockListedCookie(cookieName)
  }

  const originalLocalStorageSetItem = localStorage.setItem
  localStorage.setItem = function (key, value) {
    if (shouldCookieBeBlocked(key)) {
      return
    }
    originalLocalStorageSetItem.call(localStorage, key, value)
  }

  const cookieDesc =
    Object.getOwnPropertyDescriptor(Document.prototype, "cookie") ||
    Object.getOwnPropertyDescriptor(HTMLDocument.prototype, "cookie")
  if (cookieDesc && cookieDesc.configurable) {
    Object.defineProperty(document, "cookie", {
      get: function () {
        return cookieDesc.get?.call(document)
      },
      set: function (val) {
        const [key] = (val.split(";")[0] ?? "").trim().split("=") ?? ["", ""]
        if (!shouldCookieBeBlocked(key)) cookieDesc.set?.call(document, val)
      },
    })
  }
}

function initAttemptsToCallReachSetLoaded() {
  let attempts = 0
  const timeout = 125
  const maxAttempts = Math.floor(60_000 / timeout) // 1 minute of attempts

  function tryToCallOnAPILoaded() {
    attempts += 1
    if (attempts > maxAttempts) {
      console.error("OnAPILoaded not hooked")
      return
    }

    setTimeout(() => {
      if (window.Ecwid == null || window.Reach == null) {
        tryToCallOnAPILoaded()
      } else {
        window.Ecwid.OnAPILoaded.add(() => {
          window.Reach?.setToLoaded()
        })
      }
    }, timeout)
  }

  tryToCallOnAPILoaded()
}

function initializeCheckoutFullNameValidator() {
  type FormValidationSupport = {
    isFormValid: boolean
    validationInstructions: {
      element: HTMLInputElement
      validators: ((input: HTMLInputElement) =>
        | { valid: true; message: undefined }
        | {
            valid: false
            message: string
          })[]
    }[]
    validateForm: () => {
      result: { element: HTMLInputElement; errorMessage: string | undefined }[]
      isFormValid: boolean
    }
  }

  function handleOnPageLoad(page: { type: string }) {
    if (page.type !== "CHECKOUT_ADDRESS" && page.type !== "CHECKOUT_PAYMENT_DETAILS") {
      return
    }

    const submitButton = document.querySelector(".ec-store__checkout-page button")

    if (!submitButton) {
      console.error(`Cannot find .ec-cart__body's button`)
      return
    }

    if (page.type === "CHECKOUT_PAYMENT_DETAILS") {
      const businessAddressToggleLink = document.querySelector(
        ".ec-store__checkout-page .ec-cart-step--payment .ec-link",
      )
      businessAddressToggleLink?.addEventListener("click", () => {
        setTimeout(() => tryToInitializeValidators(), 250)
      })
    }

    const formSupport: FormValidationSupport = {
      isFormValid: true,
      validationInstructions: [],
      validateForm: () => {
        const result = formSupport.validationInstructions.map(({ element, validators }) => {
          const errorMessage = validators.reduce<string | undefined>((prev, currentValue) => {
            if (prev) {
              return prev
            }
            return currentValue(element).message
          }, undefined)
          return { element, errorMessage }
        })
        const isFormValid = !result.some((x) => x.errorMessage != null)
        formSupport.isFormValid = isFormValid

        formSupport.validationInstructions
          .map((x) => x.element)
          .forEach((element) => {
            removeError(element)
          })

        result.forEach((x) => {
          if (!x.errorMessage) {
            return
          }

          addError(x.element, x.errorMessage)
        })

        return { result, isFormValid: isFormValid }
      },
    }

    submitButton.addEventListener("click", (e) => {
      handleFormSubmission(e)
    })

    tryToInitializeValidators()

    function tryToInitializeValidators() {
      document
        .querySelectorAll<HTMLInputElement>(".ec-store__checkout-page input")
        .forEach((input) => {
          input.removeEventListener("keydown", preventEnterKeyFormSubmittingWhenFormIsInvalid, {
            capture: true,
          })
          input.addEventListener("keydown", preventEnterKeyFormSubmittingWhenFormIsInvalid, {
            capture: true,
          })
        })

      formSupport.validationInstructions = []

      const fullName = document.querySelector<HTMLInputElement>(
        ".ec-store__checkout-page #ec-full-name",
      )
      const country = document.querySelector<HTMLInputElement>(
        '.ec-store__checkout-page input[name="Country"]',
      )

      if (fullName) {
        formSupport.validationInstructions.push({
          element: fullName,
          validators: [isValidFullName],
        })
        fullName.removeEventListener("keydown", handleRemoveError)
        fullName.addEventListener("keydown", handleRemoveError)
      }
      if (country) {
        const countrySelect = country?.nextElementSibling
        formSupport.validationInstructions.push({ element: country, validators: [isValidCountry] })
        countrySelect?.removeEventListener("change", handleRemoveError)
        countrySelect?.addEventListener("change", handleRemoveError)
      }
    }

    function handleRemoveError(e: Event) {
      if (e.target instanceof HTMLElement && !formSupport.isFormValid) {
        removeError(e.target)
      }
    }

    function preventEnterKeyFormSubmittingWhenFormIsInvalid(e: KeyboardEvent) {
      if (e.key !== "Enter") {
        return
      }

      const { isFormValid } = formSupport.validateForm()

      if (!isFormValid) {
        e.preventDefault()
        e.stopImmediatePropagation()
      }
    }

    function handleFormSubmission(e: Event) {
      const { isFormValid } = formSupport.validateForm()

      if (!isFormValid) {
        e.stopImmediatePropagation()
      }
    }

    function isValidCountry(country: HTMLInputElement) {
      const { value } = country
      const expectedCountryName = getTranslatedText(translations.countryName.sweden)
      if (value === expectedCountryName) {
        return {
          valid: true,
          message: undefined,
        } as const
      }

      return {
        valid: false,
        message: getTranslatedText(translations.formError["country-invalid"]),
      } as const
    }

    function isValidFullName(fullName: HTMLInputElement) {
      const { value } = fullName
      const words = value.trim().split(" ").length
      if (words > 1) {
        return {
          valid: true,
          message: undefined,
        } as const
      }
      return {
        valid: false,
        message: getTranslatedText(translations.formError["fullName-twoWords"]),
      } as const
    }

    const inputErrorClassName = `form-control--error`

    function removeError(element: HTMLElement) {
      element.parentElement?.classList.remove(inputErrorClassName)
      removeMarker(element)
      clearMessages(element)
    }

    function removeMarker(element: HTMLElement) {
      const marker = getMarker(element)
      marker?.classList.remove(errorMarkerClassName)
    }

    function getMarker(element: HTMLElement) {
      return element.closest(".ec-form__cell")?.querySelector(".marker-required")
    }

    const errorMarkerClassName = "marker-required--active"

    function clearMessages(element: HTMLElement) {
      element
        .closest(".form-control")
        ?.parentElement?.querySelectorAll(".form__msg--error")
        .forEach((x) => x.remove())
    }

    function addError(element: HTMLInputElement, errorMessage: string) {
      const formControl = element.closest(".form-control")!
      if (!formControl) {
        console.error(`.form-control couldn't be found`)
        return
      }

      addMarker(element)
      changeInputStyle()
      addMessage(errorMessage)

      function addMarker(element: HTMLInputElement) {
        const marker = getMarker(element)
        if (!marker) {
          console.error(`.marker-required couldn't be found`)
          return
        }
        marker?.classList.add(errorMarkerClassName)
      }

      function changeInputStyle() {
        formControl.classList.add(inputErrorClassName)
      }

      function addMessage(errorMessage: string) {
        const msgDev = document.createElement("div")
        msgDev.className = "form__msg form__msg--error"
        msgDev.id = "ec-name-msg"
        msgDev.innerText = errorMessage

        formControl.insertAdjacentElement("afterend", msgDev)
      }
    }
  }

  if (window.Reach == null) {
    return
  }

  window.Reach.onLoad = {
    fn() {
      window.Ecwid?.OnPageLoaded.add(handleOnPageLoad)
    },
    options: {
      skipDynamicInit: true,
    },
  }
}

function initializeCountryAddressModificator() {
  if (window.Reach == null) {
    return
  }

  window.Reach.onLoad = {
    fn() {
      window.Ecwid?.OnPageLoaded.add((page) => {
        if (page.type !== "CHECKOUT_ADDRESS" && page.type !== "CHECKOUT_PAYMENT_DETAILS") {
          return
        }

        const swedenOption: HTMLOptionElement | null = document.querySelector(
          '#ec-country option[value="SE"]',
        )
        const select: HTMLSelectElement | null = document.querySelector("#ec-country")

        setTimeout(() => {
          if (!swedenOption || !select) {
            console.error("Cannot find Sweden option")
            return
          }

          const coutryNameInput: HTMLInputElement | null =
            swedenOption.closest(".form-control")?.querySelector("input") ?? null

          if (!coutryNameInput) {
            console.error("Country name input cannot be find")
            return
          }

          document.querySelectorAll("#ec-country option").forEach((x) => {
            if (
              !["SE"].includes(x.getAttribute("value") ?? "") &&
              !(x as HTMLOptionElement).selected
            ) {
              x.setAttribute("disabled", "disabled")
            }
          })
        }, 0)
      })
    },
    options: {
      skipDynamicInit: true,
    },
  }
}

const supportedLanguages = ["en", "sv"] as const
type SupportedLanguage = (typeof supportedLanguages)[number]

function getTranslatedText(translations: Record<SupportedLanguage, string>) {
  const language = window.Ecwid?.acceptLanguage?.[0]
  const key: SupportedLanguage =
    supportedLanguages.indexOf(language as SupportedLanguage) !== -1
      ? (language as SupportedLanguage)
      : ("sv" as const)
  return translations[key]
}

const translations = {
  ["formError"]: {
    ["fullName-twoWords"]: {
      en: "Enter first name and last name",
      sv: "Ange förnamn och efternamn",
    },
    ["country-invalid"]: {
      en: "Shipping only to Sweden is supported at the moment",
      sv: "Frakt endast till Sverige stöds för närvarande",
    },
  },
  ["countryName"]: {
    sweden: {
      en: "Sweden",
      sv: "Sverige",
    },
  },
  ["navigation"]: {
    search: {
      en: "search",
      sv: "Sök",
    },
  },
}

function initializeProductModalOpening() {
  const requiredClass = "reach-product-tile--opens-modal"
  document.addEventListener("click", handleDocumentClick, { capture: true })
  injectCssSCript()

  let isOpenViaThisFeature = false

  if (window.Reach == null) {
    return
  }

  window.Reach.onLoad = {
    fn: () => {
      window.Ecwid?.OnPageSwitch.add((page) => {
        if (!isOpenViaThisFeature) {
          return
        }

        if (page.type === "CATEGORY") {
          const button: HTMLButtonElement | null = document.querySelector(
            ".ecwid-popup .ecwid-popup-closeButton",
          )
          button?.click()
          isOpenViaThisFeature = false
        }
      })
    },
    options: { skipDynamicInit: true },
  }

  function injectCssSCript() {
    const stylesheet = document.createElement("style")
    stylesheet.innerText = `
.${requiredClass}{
  cursor: pointer
}'
`
    document.head.appendChild(stylesheet)
  }

  function openModal(productId: string) {
    isOpenViaThisFeature = true
    window.Ecwid?.openPage("product", { id: productId })
  }

  function handleDocumentClick(e: MouseEvent) {
    if (!(e.target instanceof Element)) {
      return
    }

    const ecwidProduct = e.target.closest(
      `.ecsp-SingleProduct-v2.${requiredClass}, .ecwid-SingleProduct-v2.${requiredClass}`,
    )
    const isClickIntoEcwidProduct = ecwidProduct != null

    if (!isClickIntoEcwidProduct) {
      return
    }

    const isClickedInteractiveElement =
      e.target.closest("button, a, input, select, textarea") != null

    if (isClickedInteractiveElement) {
      return
    }

    const productId = ecwidProduct.getAttribute("data-single-product-id")

    if (productId) {
      e.stopImmediatePropagation()
      openModal(productId)
    } else {
      console.error("ProductId not found on a product")
    }
  }
}
