console.log("Publisher Mods Loaded!")

let yTreshold = 0

window.addEventListener("scroll", function () {
  handleMinimizedMenuBarAppearance()
})

window.addEventListener("resize", function () {
  setYThreshold()
})

setYThreshold()

const topBarMods = {
  isBgOn: false,
  makeMinimized: () => {
    topBarMods.isBgOn = true
    const topBar = document.querySelector("#main-container .menu-bar")
    if (topBar) {
      topBar.classList.add("menu-bar--minimized", "menu-bar--bg")
    }
  },
  makeNotMinimized: () => {
    topBarMods.isBgOn = false
    const topBar = document.querySelector("#main-container .menu-bar")
    if (topBar) {
      topBar.classList.remove("menu-bar--minimized", "menu-bar--bg")
    }
  },
}

let timeoutHandler: number | undefined = undefined

function handleMinimizedMenuBarAppearance() {
  clearTimeout(timeoutHandler)

  timeoutHandler = setTimeout(() => {
    if (window.scrollY > yTreshold && !topBarMods.isBgOn) {
      topBarMods.makeMinimized()
    } else if (window.scrollY < yTreshold * 0.9 && topBarMods.isBgOn) {
      topBarMods.makeNotMinimized()
    }
  }, 5)
}

function setYThreshold() {
  const menuBarBgHeight =
    document.querySelector<HTMLDListElement>("#main-container > .menu-bar .menu-bar__bg")
      ?.offsetHeight ?? 0
  const topSecionHeight =
    document.querySelector<HTMLDListElement>("#main-container > .top-section")?.offsetHeight ?? 0

  yTreshold = topSecionHeight - menuBarBgHeight
}
